import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { createIntl } from 'react-intl';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatFileSize, getLanguage } from '@skiwo/utils';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import 'dropzone/dist/dropzone.css';
import uploadFileImage from '../assets/illustration-upload-file.svg';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './FileSelector.module.scss';

interface Props {
  selectedFiles: FileState[];
  maxSize?: number;
  maxFiles?: number;
  noDrag?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  acceptedFileTypes?: { [key: string]: string[] };
  onSelection?: (newFiles: FileState[]) => void;
  'data-testid'?: string;
}

export interface FileState extends File {
  id?: number;
  uid: string;
  name: string;
  size: number;
  type: string;
  data: Uint8Array;
  preview: string;
}

export function objectToFile(object: FileState): File {
  const blob = new Blob([object.data], { type: object.type });
  return new File([blob], object.name, { type: object.type, lastModified: object.lastModified });
}

const FileSelector = (props: Props) => {
  const {
    selectedFiles = [],
    maxFiles = 10,
    maxSize = 50 * 1024 * 1024, // default 50MB
    noDrag = false,
    multiple = true,
    disabled,
    autoFocus,
    acceptedFileTypes,
    onSelection,
  } = props;

  const [files, setFiles] = useState<FileState[]>(selectedFiles);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDragOver, setIsDragOver] = useState(false);
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const fileToObject = async (file: File): Promise<FileState> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target && event.target.result) {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = new Uint8Array(arrayBuffer);
          await resolve({
            uid: uuidv4(),
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            webkitRelativePath: file.webkitRelativePath,
            arrayBuffer: file.arrayBuffer,
            slice: file.slice,
            stream: file.stream,
            text: file.text,
            data: data,
            preview: URL.createObjectURL(file),
          });
        }
      };
      reader.onerror = () => {
        reject(new Error('Failed to read file.'));
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const promises: Promise<FileState>[] = [];

      rejectedFiles.filter(async (file) => {
        const fileLength = rejectedFiles.length;
        if (fileLength > maxFiles) {
          setErrorMessage(
            intl.formatMessage({ id: translationKeys.file_uploader_error_files_limit }),
          );
        } else if (file.errors.find((e) => e.code === 'file-too-large')) {
          setErrorMessage(
            intl.formatMessage({ id: translationKeys.file_uploader_error_max_file_size }),
          );
        } else if (file.errors.find((e) => e.code === 'file-invalid-type')) {
          setErrorMessage(
            intl.formatMessage({ id: translationKeys.file_uploader_error_invalid_file_type }),
          );
        } else {
          setErrorMessage('');
          return promises.push(fileToObject(file.file));
        }
      });

      acceptedFiles.filter(async (file) => {
        const fileLength = files.length;
        if (fileLength >= maxFiles) {
          setErrorMessage(
            intl.formatMessage({ id: translationKeys.file_uploader_error_files_limit }),
          );
        } else if (files.find((f) => f.name === file.name)) {
          setErrorMessage(
            intl.formatMessage({ id: translationKeys.file_uploader_error_file_exists }),
          );
        } else {
          setErrorMessage('');
          return promises.push(fileToObject(file));
        }
      });

      const newFiles = await Promise.all(promises);
      const allFiles = [...newFiles, ...files];

      setFiles(allFiles);
      if (newFiles.length && onSelection) {
        onSelection(newFiles);
      }

      setIsDragOver(false);
    },
    [files],
  );

  useEffect(() => {
    setFiles(selectedFiles);
  }, [selectedFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    maxSize,
    noDrag,
    multiple,
    autoFocus,
    accept: acceptedFileTypes,
    onDrop: handleDrop,
    disabled,
    onDragEnter: () => setIsDragOver(true),
    onDragLeave: () => setIsDragOver(false),
    onDragOver: () => setIsDragOver(true),
  });

  const fileUploaderStyle = classnames(
    styles.fileUploader,
    { [styles['isDragOver']]: isDragOver },
    { [styles['disabled']]: disabled },
    { [styles['error']]: errorMessage },
  );

  return (
    <article data-testid={props['data-testid']}>
      <div
        {...{ ...getRootProps(), disabled }}
        className={fileUploaderStyle}
        data-testid="file-uploader-container"
      >
        <input {...{ ...getInputProps(), disabled }} />
        <div>
          <div className={styles.content}>
            <img src={uploadFileImage} alt="File uploader" data-testid="file-uploader-image-icon" />
            <span className={styles.description} data-testid="file-uploader-description">
              {intl.formatMessage(
                { id: translationKeys.file_uploader_main_description },
                {
                  action: (
                    <span className={styles.action}>
                      {intl.formatMessage({
                        id: translationKeys.file_uploader_main_description_action_text,
                      })}
                    </span>
                  ),
                },
              )}
            </span>
            <span className={styles.hint} data-testid="file-uploader-hint">
              {intl.formatMessage(
                { id: translationKeys.file_uploader_hint },
                { maxFiles: maxFiles, maxSize: formatFileSize(maxSize) },
              )}
            </span>
          </div>
        </div>
      </div>
      <div>
        {errorMessage && (
          <p className={styles.errorMessage} data-testid="file-uploader-error-message">
            <FontAwesomeIcon className={styles.icon} icon={faTriangleExclamation} size="lg" />
            {errorMessage}
          </p>
        )}
      </div>
    </article>
  );
};

export default FileSelector;
