import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import B_Tooltip from 'react-bootstrap/Tooltip';
import { createIntl } from 'react-intl';
import { getLanguage } from '@skiwo/utils';
import classnames from 'classnames';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './Tooltip.module.scss';

interface Props {
  children: React.ReactElement;
  title?: string;
  subtitle?: string;
  list?: React.ReactNode;
  copyable?: boolean;
}

const Tooltip = (props: Props) => {
  const { children, title, subtitle, copyable, list } = props;
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const handleCopy = () => {
    if (!copyable || !title) return;

    navigator.clipboard.writeText(title);
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        list ? (
          <B_Tooltip>
            <span>{list}</span>
          </B_Tooltip>
        ) : title || subtitle ? (
          <B_Tooltip>
            <span data-testid="tooltip-title">{title}</span>
            {subtitle && !copyable && <span>{subtitle}</span>}
            {copyable && (
              <span>{intl.formatMessage({ id: translationKeys.tooltip_click_to_copy_text })}</span>
            )}
          </B_Tooltip>
        ) : (
          <></>
        )
      }
    >
      <div onClick={handleCopy} className={classnames({ [styles.copyable]: copyable && title })}>
        {children}
      </div>
    </OverlayTrigger>
  );
};

export default Tooltip;
