const getLanguage = () => {
  const preferredLocale = window.localStorage.getItem('locale');

  const navigatorLocale = window.navigator.language;

  const locale = preferredLocale || navigatorLocale;
  const language = locale.split('-')[0];

  if (language) {
    return language;
  }

  return 'nb';
};

export default getLanguage;
