import React, { createContext, useContext, useMemo, useState } from 'react';
import { LogoutOrderFile } from '../types/LogoutOrderFile';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export interface IOrderInfoContext {
  selectedEnterprise: number | null;
  sourceLanguage: number | null;
  targetLanguages: number[];
  files: LogoutOrderFile[];
  deadline: Date | null;
  totalWordCount: number;
  handleSetSelectedEnterprise: (selectedEnterpriseId: number | null) => any;
  handleSetSourceLanguage: (sourceLanguageId: number | null) => any;
  handleSetTargetLanguages: (targetLanguageIds: number[]) => any;
  handleSetDeadline: (deadline: Date | null) => any;
  handleSetFiles: (files: LogoutOrderFile[]) => any;
  setFiles: React.Dispatch<React.SetStateAction<LogoutOrderFile[]>>;
  handleSetTotalWordCount: (wordCount: number) => any;
}

export const initialState: IOrderInfoContext = {
  selectedEnterprise: null,
  sourceLanguage: null,
  targetLanguages: [],
  files: [],
  deadline: null,
  totalWordCount: 0,
  handleSetSelectedEnterprise: () => null,
  handleSetSourceLanguage: () => null,
  handleSetTargetLanguages: () => null,
  handleSetDeadline: () => null,
  handleSetFiles: () => null,
  setFiles: () => null,
  handleSetTotalWordCount: () => null,
};

export const OrderInfoContext = createContext(initialState);

const OrderInfoProvider: React.FC<Props> = ({ children }) => {
  const [sourceLanguage, setSourceLanguage] = useState<number | null>(null);
  const [targetLanguages, setTargetLanguages] = useState<number[]>([]);
  const [files, setFiles] = useState<LogoutOrderFile[]>([]);
  const [deadline, setDeadline] = useState<Date | null>(null);
  const [totalWordCount, setTotalWordCount] = useState<number>(0);
  const [selectedEnterprise, setSelectedEnterprise] = useState<number | null>(null);

  const handleSetSelectedEnterprise = (selectedEnterprise: number | null) => {
    setSelectedEnterprise(selectedEnterprise);
  };

  const handleSetSourceLanguage = (sourceLanguageId: number | null) => {
    setSourceLanguage(sourceLanguageId);
  };

  const handleSetTargetLanguages = (targetLanguageIds: number[]) => {
    setTargetLanguages(targetLanguageIds);
  };

  const handleSetDeadline = (deadline: Date | null) => {
    setDeadline(deadline);
  };

  const handleSetFiles = (files: LogoutOrderFile[]) => {
    setFiles(files);
  };

  const handleSetTotalWordCount = (wordCount: number) => {
    setTotalWordCount(wordCount);
  };

  const OrderInfoContextValue = useMemo(
    () => ({
      selectedEnterprise,
      sourceLanguage,
      targetLanguages,
      files,
      deadline,
      totalWordCount,
      handleSetSelectedEnterprise,
      handleSetSourceLanguage,
      handleSetTargetLanguages,
      handleSetDeadline,
      handleSetFiles,
      setFiles,
      handleSetTotalWordCount,
    }),
    [
      selectedEnterprise,
      sourceLanguage,
      targetLanguages,
      files,
      deadline,
      totalWordCount,
      handleSetSelectedEnterprise,
      handleSetSourceLanguage,
      handleSetTargetLanguages,
      handleSetDeadline,
      handleSetFiles,
      setFiles,
      handleSetTotalWordCount,
    ],
  );

  return (
    <OrderInfoContext.Provider value={OrderInfoContextValue}>{children}</OrderInfoContext.Provider>
  );
};

export const useOrderInfo = () => useContext(OrderInfoContext);

export default OrderInfoProvider;
