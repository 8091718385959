import { DepartmentResponse } from '../../types/Department';
import {
  EnterpriseSearchResultResponse,
  WhiteListedEnterpriseResponse,
} from '../../types/Enterprise';
import fetchApi, { UrlParams } from '../fetchApi';

export default {
  publicSearchEnterprises: (
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<EnterpriseSearchResultResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/public_search`,
      urlParams,
      loadingCallback,
    });
  },
  getEnterpriseDepartments: (
    enterpriseId: string,
    urlParams?: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<DepartmentResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/${enterpriseId}/departments`,
      urlParams,
      loadingCallback,
    });
  },
  getWhitelistedEnterprises: (
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<WhiteListedEnterpriseResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/whitelisted_enterprises`,
      urlParams,
      loadingCallback,
    });
  },
};
