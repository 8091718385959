import React, { useEffect, useState } from 'react';
import DatePickerCalendar from './DatePickerCalendar/DatePickerCalendar';

interface Props {
  onChange?: (start: Date, end?: Date) => void;
  onClose?: () => void;
  monthCount?: number;
  className?: any;
  singleDate?: boolean;
  minDate?: Date;
  selected?: Date;
}
const DatePicker = (props: Props) => {
  const {
    onChange,
    onClose,
    singleDate = false,
    monthCount = singleDate ? 1 : 2,
    className,
    minDate,
    selected,
  } = props;
  const [showCalendar, setShowCalendar] = useState(true);

  const handleClose = () => {
    setShowCalendar(false);

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const handleDateChange = (start: Date, end?: Date) => {
    setShowCalendar(false);

    if (onChange) {
      onChange(start, end);
    }
  };

  if (!showCalendar) return null;

  return (
    <DatePickerCalendar
      onClose={handleClose}
      onChange={handleDateChange}
      className={className}
      monthCount={monthCount}
      singleDate={singleDate}
      minDate={minDate}
      selected={selected}
    />
  );
};

export default DatePicker;
