import ReactGA from 'react-ga4';

export enum GAEventType {
  PageLanding = 't_flow_started',
  ContactEmailClicked = 't_email_clicked',
  FileUploaded = 't_file_uploaded',
  ContactPageLanded = 't_contact_page_landed',
  OrderDrafted = 't_order_drafted',
  OfferPresented = 't_offer_presented',
  OrderAbandoned = 't_order_abandoned',
  OrderPlaced = 't_order_placed',
}

const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const isProd = process.env.NODE_ENV === 'production';

export const initializeGA = () => {
  if (MEASUREMENT_ID && isProd) {
    ReactGA.initialize(MEASUREMENT_ID);
  }
};

export const sendGAEvent = (eventType: GAEventType, params?: any) => {
  if (isProd) {
    ReactGA.event(eventType, params);
  }
};
