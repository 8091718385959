import React from 'react';
import { Spinner as B_Spinner } from 'react-bootstrap';

interface Props {
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger';
  className?: any;
}

const Spinner = (props: Props) => {
  const { color = 'primary', className } = props;
  return <B_Spinner animation="border" variant={color} className={className}/>;
};

export default Spinner;
