const formatFileSize = (bytes: number): string => {
  if (bytes < 1024 * 1024) {
    const kilobytes = Math.round(bytes / 1024);
    return `${kilobytes} KB`;
  }
  const megabytes = Math.round(bytes / (1024 * 1024));
  return `${megabytes} MB`;
};

export default formatFileSize;
