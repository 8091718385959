import React from 'react';
import { faCircleExclamation, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './InlineError.module.scss';

interface Props {
  text: string;
  variant: 'error' | 'warning';
}

const InlineError = (props: Props) => {
  const { text, variant } = props;
  return (
    <article
      className={classnames(styles.container, styles[variant])}
      data-testid="file-item-error-message"
    >
      <span>
        {variant == 'warning' ? (
          <FontAwesomeIcon
            className={styles.icon}
            icon={faCircleExclamation}
            size="lg"
            data-testid="warning-icon"
          />
        ) : (
          <FontAwesomeIcon
            className={styles.icon}
            icon={faTriangleExclamation}
            size="lg"
            data-testid="error-icon"
          />
        )}
      </span>

      <span data-testid="inline-text">{text}</span>
    </article>
  );
};

export default InlineError;
