import { getLanguage } from '@skiwo/utils';
import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig } from 'axios';

const LOCAL_STORAGE_TOKEN_KEY = 'token';

export type RequestMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
export type UrlParams = Record<string, any>;

interface FetchParams {
  method: RequestMethod;
  url: string;
  loadingCallback?: (isLoading: boolean) => void;
  urlParams?: UrlParams;
  body?: any;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => any;
}

export interface ApiError {
  code: number;
  text: any;
}

export interface ApiResponse<T> {
  data: T | null;
  error: ApiError | null;
}

async function fetchApi<T>(params: FetchParams): Promise<ApiResponse<T>> {
  const { method, url, loadingCallback, urlParams, body, onUploadProgress } = params;

  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  const locale = getLanguage();

  if (loadingCallback) {
    loadingCallback(true);
  }

  const options: AxiosRequestConfig = {
    url,
    method,
    headers: {},
    data: body,
    params: urlParams,
    onUploadProgress,
  };

  if (options.headers) {
    options.headers['Accept-Language'] = locale;

    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }

    if (body instanceof FormData) {
      options.headers['Content-Type'] = 'multipart/form-data';
    }
  }

  try {
    const response = await axios(options);

    return { data: response.data, error: null };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      if (error.response.status === 401) {
        // TODO: handle error globally
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
      }

      return {
        data: null,
        error: { code: error.response.status, text: error.response.data.errors },
      };
    } else {
      return { data: null, error: { code: 520, text: 'an unexpected error occurred.' } };
    }
  } finally {
    if (loadingCallback) {
      loadingCallback(false);
    }
  }
}

export default fetchApi;
