import React from 'react';
import styles from './CircularProgressBar.module.scss';

interface Props {
  percentage?: number;
}

const CircularProgressBar = (props: Props) => {
  const { percentage = 0 } = props;
  const radius = 15;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <svg className={styles.progress} width="40" height="40">
      <circle
        className={styles.progressBarBackground}
        cx="20"
        cy="20"
        r={radius}
      />
      <circle
        data-testid="circular-progress-bar"
        className={styles.progressBar}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={offset}
        cx="20"
        cy="20"
        r={radius}
      />
    </svg>
  );
};

export default CircularProgressBar;
