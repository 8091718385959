import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner } from '@skiwo/components';
import { GAEventType, sendGAEvent } from '../../../helpers/GoogleAnalytics';
import { useLanguages } from '../../../providers/LanguagesProvider';
import { useOrderBooking } from '../../../providers/OrderBookingProvider';
import translationKeys from '../../../translations/translationKeys';
import styles from './InvoiceSummary.module.scss';

interface Props {
  estimate: string;
  invoiceHeader: 'showQuote' | 'hideQuote' | 'noOrgNumber' | 'frameAgreement';
  'data-testid'?: string;
}

const BookingEstimate = (props: Props) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const { estimate, invoiceHeader } = props;
  const { order } = useOrderBooking();

  return (
    <div className={styles.content} data-testid={props['data-testid']}>
      <div className={styles.estimateContainer}>
        {invoiceHeader === 'hideQuote' && (
          <Banner
            data-testid="quote-unavailable-banner"
            variant="information"
            text={intl.formatMessage({
              id: translationKeys.booking_estimate_quote_unavailable_banner,
            })}
          />
        )}
        {invoiceHeader === 'noOrgNumber' && (
          <Banner
            data-testid="no-org-number-banner"
            variant="information"
            text={intl.formatMessage({
              id: translationKeys.booking_estimate_provide_org_number_for_quote,
            })}
          />
        )}
        {invoiceHeader === 'frameAgreement' && (
          <Banner
            data-testid="frame-agreement-banner"
            variant="information"
            text={intl.formatMessage({
              id: translationKeys.booking_estimate_frame_agreement_banner,
            })}
          />
        )}
        {invoiceHeader === 'showQuote' && (
          <>
            <h1 className={styles.price} data-testid="booking-estimate-price">
              <span>
                {estimate} {'NOK'}
              </span>
            </h1>
            <h4 className={styles.title}>
              <FormattedMessage id={translationKeys.booking_estimate_tooltip_label} />
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <span>
                      <FormattedMessage
                        key={translationKeys.booking_estimate_price_tooltip}
                        id={translationKeys.booking_estimate_price_tooltip}
                        values={{
                          email: (
                            <a
                              href="mailto: oversettelse@salita.no"
                              onClick={() => sendGAEvent(GAEventType.ContactEmailClicked)}
                            >
                              {'oversettelse@salita.no'}
                            </a>
                          ),
                        }}
                      />
                    </span>
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
              </OverlayTrigger>
            </h4>
          </>
        )}
        <div className={styles.divider} />
        <div>
          <h4 className={styles.title}>
            <FormattedMessage id={translationKeys.booking_estimate_from_text} />
          </h4>
          {order?.sourceLanguageId && (
            <span className={styles.value} data-testid="source-language">
              {getLanguageById(order.sourceLanguageId)}
            </span>
          )}
        </div>
        <div>
          <h4 className={styles.title}>
            <FormattedMessage id={translationKeys.booking_estimate_to_text} />
          </h4>
          <div data-testid="target-languages">
            {order?.targetLanguages.map((id) => (
              <h6 key={id} className={styles.value}>
                {getLanguageById(id)}
              </h6>
            ))}
          </div>
        </div>
        <div>
          <h4 className={styles.title}>
            <FormattedMessage id={translationKeys.booking_estimate_files_text} /> (
            {order && order.initialAttachmentsNames.length})
          </h4>
          <div data-testid="order-files">
            {order?.initialAttachmentsNames.map((name) => (
              <h6 key={name} className={styles.value}>
                {name}
              </h6>
            ))}
          </div>
        </div>
        <div>
          <h4 className={styles.title}>
            <FormattedMessage id={translationKeys.booking_estimate_word_count} />
          </h4>
          <span className={styles.value} data-testid="word-count">
            {order?.wordCount}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BookingEstimate;
