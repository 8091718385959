// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from 'react';

const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION;

const useHotjar = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && HOTJAR_ID && HOTJAR_VERSION) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            // eslint-disable-next-line prefer-rest-params
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: HOTJAR_ID, hjsv: HOTJAR_VERSION };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }, []);

  return null;
};

export default useHotjar;
