import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { useLanguages } from '../../../providers/LanguagesProvider';
import translationKeys from '../../../translations/translationKeys';
import { Order } from '../../../types/Order';
import { TranslationMethod } from '../../../types/TranslationMethod';
import styles from './OrderSummary.module.scss';

interface Prop {
  order: Order | undefined;
  translationMethods: TranslationMethod[];
}

const OrderSummary = (props: Prop) => {
  const { order, translationMethods } = props;
  const { getLanguageById } = useLanguages();

  const renderSubject = () => {
    const subject = translationMethods?.find((method) => method.id == order?.translationMethodId);
    const subjectName = subject ? subject.name : order?.otherSubject;
    return (
      <div className={styles.container}>
        <span className={styles.title}>
          <FormattedMessage id={translationKeys.order_summary_subject_title_text} />
        </span>
        <span className={styles.value} data-testid="subject">
          {subjectName || '-'}
        </span>
      </div>
    );
  };

  const renderDivider = () => {
    return <div className={styles.divider} />;
  };

  return (
    <article className={styles.container}>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id={translationKeys.order_summary_title} />
        </h3>
        <span className={styles.subtitle}>
          <FormattedMessage id={translationKeys.order_summary_subtitle} />
        </span>
      </div>
      <div className={styles.section}>
        <h5 className={styles.header}>
          <FormattedMessage id={translationKeys.order_summary_details_section} />
        </h5>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_from_title_text} />
          </span>
          <span className={styles.value} data-testid="source-language">
            {order?.sourceLanguageId ? getLanguageById(order.sourceLanguageId) : '-'}
          </span>
        </div>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_to_title_text} />
          </span>
          <div className={styles.list} data-testid="target-languages">
            {order?.targetLanguages.map((targetLanguageId) => {
              return (
                <span key={targetLanguageId} className={styles.value}>
                  {getLanguageById(targetLanguageId)}
                </span>
              );
            })}
          </div>
        </div>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_deadline_title_text} />
          </span>
          <span className={styles.value} data-testid="deadline">
            {order?.externalDeadline ? format(new Date(order.externalDeadline), 'dd.MM.yyyy') : '-'}
          </span>
        </div>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_files_title_text} /> (
            {order && order?.initialAttachmentsNames.length})
          </span>
          <div className={styles.list} data-testid="files">
            {order?.initialAttachmentsNames.map((fileName) => {
              return (
                <span key={fileName} className={styles.value}>
                  {fileName}
                </span>
              );
            })}
          </div>
        </div>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_word_count_title_text} />
          </span>
          <span className={styles.value} data-testid="word-count">
            {order?.wordCount ? order.wordCount : '-'}
          </span>
        </div>
      </div>
      {renderDivider()}
      <div className={styles.section}>
        <h5 className={styles.header}>
          <FormattedMessage id={translationKeys.order_summary_invoicing_section} />
        </h5>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_booker_title_text} />
          </span>
          <span className={styles.value} data-testid="booker">
            {order?.owner ? order.owner.name : '-'}
          </span>
        </div>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_enterprise_title_text} />
          </span>
          <span className={styles.value} data-testid="enterprise">
            {order?.enterprise.name
              ? order.enterprise.name + ' (' + order.enterprise.orgNumber + ')'
              : '-'}
          </span>
        </div>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_order_reference_title_text} />
          </span>
          <span className={styles.value} data-testid="payment-booking-ref">
            {order?.paymentBookingReference ? order.paymentBookingReference : '-'}
          </span>
        </div>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_buyer_reference_title_text} />
          </span>
          <span className={styles.value} data-testid="buyer-booking-ref">
            {order?.bookingReference ? order.bookingReference : '-'}
          </span>
        </div>
      </div>
      {renderDivider()}
      <div className={styles.section}>
        <h5 className={styles.header}>
          <FormattedMessage id={translationKeys.order_summary_additional_info_section} />
        </h5>
        {renderSubject()}
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_case_number_title_text} />
          </span>
          <span className={styles.value} data-testid="case-number">
            {order?.caseNumber ? order.caseNumber : '-'}
          </span>
        </div>
        <div className={styles.container}>
          <span className={styles.title}>
            <FormattedMessage id={translationKeys.order_summary_description_title_text} />
          </span>
          <span className={styles.value} data-testid="description">
            {order?.instructionsFromDemander ? order.instructionsFromDemander : '-'}
          </span>
        </div>
      </div>
    </article>
  );
};

export default OrderSummary;
