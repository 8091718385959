import { AxiosProgressEvent } from 'axios';
import { FileUploadResponse } from '../../types/LogoutOrderFile';
import { LogoutOrderTokenResponse } from '../../types/LogoutToken';
import { OrderEstimateResponse, OrderResponse } from '../../types/Order';
import { TranslationMethodResponse } from '../../types/TranslationMethod';
import fetchApi, { UrlParams } from '../fetchApi';

export default {
  createLogoutTokens: (industry: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<LogoutOrderTokenResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v2/logout_tokens`,
      body: { industry },
      loadingCallback,
    });
  },
  createOrder: (
    body: any,
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<OrderResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v2/logout_orders`,
      body,
      urlParams,
      loadingCallback,
    });
  },
  updateFinanceInfo: (
    orderId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<OrderResponse>({
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_BASE_URL}/v2/logout_orders/${orderId}/update_finance_info`,
      body,
      loadingCallback,
    });
  },
  updateAdditionalInfo: (
    orderId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<OrderResponse>({
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_BASE_URL}/v2/logout_orders/${orderId}/update_additional_info`,
      body,
      loadingCallback,
    });
  },
  getTranslationMethods: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<TranslationMethodResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/sellers/translation_methods`,
      loadingCallback,
    });
  },
  getOrderEstimate: (
    orderId: string,
    urlParams?: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<OrderEstimateResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v2/logout_orders/${orderId}/quote`,
      urlParams,
      loadingCallback,
    });
  },
  uploadFile: (
    body: any,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<FileUploadResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v2/logout_order_attachments`,
      body,
      onUploadProgress,
      loadingCallback,
    });
  },
  deleteFile: (
    fileId: string,
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v2/logout_order_attachments/${fileId}`,
      urlParams,
      loadingCallback,
    });
  },
};
