import React, { useState } from 'react';
import B_Dropdown from 'react-bootstrap/Dropdown';
import { createIntl } from 'react-intl';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '@skiwo/utils';
import classNames from 'classnames';
import classnames from 'classnames';
import Button from '../Button/Button';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './Dropdown.module.scss';

export interface DropdownItem {
  id: string;
  text: string;
  icon?: IconDefinition;
  iconClassName?: string;
  disabled?: boolean;
  variant?: 'critical' | 'inactive';
  className?: any;
  'data-testId'?: string;
}

interface Props {
  toggle: React.ReactNode;
  items: DropdownItem[];
  label?: string;
  disabled?: boolean;
  selectedItemId?: string;
  onSelect?: (selection: string | null) => void;
  'data-testid'?: string;
}

const Dropdown = (props: Props) => {
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });
  const { toggle, items, selectedItemId, disabled, onSelect, label } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClearSelection = () => {
    setDropdownOpen(false);
    handleSelectionChange(null);
  };

  const handleSelectionChange = (eventKey: string | null) => {
    if (onSelect) {
      onSelect(eventKey);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <B_Dropdown
      onSelect={handleSelectionChange}
      onToggle={handleDropdownToggle}
      defaultValue={selectedItemId}
      show={dropdownOpen}
    >
      {label && (
        <span data-testid="dropdown-label" className={styles.label}>
          {label}
        </span>
      )}

      <B_Dropdown.Toggle
        className={styles.dropdownToggle}
        data-testid="dropdown-toggle"
        as="div"
        disabled={disabled}
      >
        {toggle}
      </B_Dropdown.Toggle>

      <B_Dropdown.Menu
        className={classnames(styles.dropdownMenu, { [styles.hasSelection]: selectedItemId })}
        data-testid={props['data-testid']}
      >
        {items.map((item) => (
          <B_Dropdown.Item
            key={item.id}
            className={classNames(styles.dropDownItem, item.className, {
              [styles.critical]: item.variant === 'critical',
            })}
            eventKey={item.id}
            active={item.id === selectedItemId}
            data-testid={item['data-testId'] || 'dropdown-item'}
            disabled={item.disabled}
          >
            {item.icon && (
              <FontAwesomeIcon
                icon={item.icon}
                className={classNames(
                  item.iconClassName,
                  item.variant && styles[item.variant],
                  item.disabled && styles.inactive,
                )}
                data-testid="dropdown-item-icon"
              />
            )}
            <span>{item.text}</span>
          </B_Dropdown.Item>
        ))}
        {selectedItemId && (
          <div className={styles.clearSelection}>
            <Button
              variant="link"
              size="medium"
              onClick={handleClearSelection}
              data-testid="dropdown-clear-button"
            >
              {intl.formatMessage({ id: translationKeys.dropdown_menu_clear_selected })}
            </Button>
          </div>
        )}
      </B_Dropdown.Menu>
    </B_Dropdown>
  );
};

export default Dropdown;
