import { Enterprises, System, Translations } from './Endpoints';

export const Api = {
  ...Enterprises,
  ...System,
  ...Translations,
};

export type ApiDefinition = typeof Api;

export { type ApiError } from './fetchApi';

export default Api;
