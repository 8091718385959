import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Banner,
  Button,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { Formik } from 'formik';
import illustrationSuccessfulOrder from '../../assets/illustration-successful-order.svg';
import { useApi } from '../../providers/ApiProvider';
import { useOrderBooking } from '../../providers/OrderBookingProvider';
import translationKeys from '../../translations/translationKeys';
import { LoggedOutCustomerType } from '../../types/LogoutCustomer';
import { TranslationMethod } from '../../types/TranslationMethod';
import OrderSummary from './OrderSummary/OrderSummary';
import styles from './FinishedBooking.module.scss';

interface Props {
  isEstimateVisible: boolean | undefined;
}

interface FormValues {
  caseNumber: string;
  description: string;
  otherSubject: string;
}

const FinishedBooking = (props: Props) => {
  const { isEstimateVisible } = props;
  const api = useApi();
  const [updateAdditionalInfoLoading, setUpdateAdditionalInfoLoading] = useState(false);
  const [subjectsList, setSubjectList] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<SearchDropdownMenuOption>();
  const [translationMethods, setTranslationMethods] = useState<TranslationMethod[]>([]);
  const { order, getLogoutOrderToken, handleSetOrder } = useOrderBooking();
  const [isAdditionalInfoSubmitted, setIsAdditionInfoSubmitted] = useState(false);
  const intl = useIntl();

  const getSubjects = async () => {
    const { data } = await api.getTranslationMethods();

    if (data && data.translationMethods) {
      const subjectOptions =
        data.translationMethods.map((translationMethod) => {
          return {
            id: translationMethod.id,
            label: translationMethod.name,
            key: translationMethod.id,
          };
        }) || [];
      setSubjectList([
        ...subjectOptions,
        {
          id: 0,
          label: intl.formatMessage({
            id: translationKeys.finished_booking_other_subject_option,
          }),
          key: 'other',
        },
      ]);
      setTranslationMethods(data.translationMethods);
    }
  };

  useEffect(() => {
    getSubjects();
  }, []);

  const handleSubmit = async (formValues: FormValues) => {
    if (!selectedSubject && !formValues.caseNumber && !formValues.description) {
      setIsAdditionInfoSubmitted(true);
      return;
    }

    getLogoutOrderToken().then(async (token) => {
      if (order && token) {
        const requestBody: {
          token: string;
          order: {
            instructions_from_demander: string;
            translation_method_id?: number;
            other_subject?: string;
            case_number: string;
          };
        } = {
          token: token,
          order: {
            case_number: formValues.caseNumber,
            instructions_from_demander: formValues.description,
          },
        };
        if (selectedSubject) {
          if (selectedSubject.key === 'other') {
            requestBody.order.other_subject = formValues.otherSubject;
          } else {
            requestBody.order.translation_method_id = selectedSubject.id;
          }
        }

        const { data } = await api.updateAdditionalInfo(
          order.id.toString(),
          requestBody,
          setUpdateAdditionalInfoLoading,
        );

        if (data) {
          handleSetOrder(data.order);
          setIsAdditionInfoSubmitted(true);
        }
      }
    });
  };

  return (
    <div className={styles.content}>
      <div className={styles.successfulOrderSection}>
        <img src={illustrationSuccessfulOrder} alt="Successful Order" />
      </div>
      <div>
        <h3 className={styles.heading}>
          {isEstimateVisible ? (
            <FormattedMessage id={translationKeys.finished_booking_header_title} />
          ) : (
            <FormattedMessage id={translationKeys.finished_booking_request_header_title} />
          )}
        </h3>
        <span className={styles.description}>
          <FormattedMessage
            id={translationKeys.finished_booking_header_subtitle}
            defaultMessage={translationKeys.finished_booking_header_subtitle}
            values={{
              link: (
                <a href="https://my.salita.no" target="_blank" rel="noreferrer">
                  <FormattedMessage id={translationKeys.finished_booking_header_subtitle_link} />
                </a>
              ),
            }}
          />
        </span>
      </div>
      {isAdditionalInfoSubmitted ? (
        <>
          <div className={styles.banner}>
            <Banner
              variant="success"
              text={intl.formatMessage({
                id: translationKeys.finished_booking_form_submitted_banner,
              })}
            />
          </div>
          <OrderSummary order={order} translationMethods={translationMethods} />
          <Link to="https://my.salita.no">
            <Button className={styles.loginButton} fullWidth={true} size="large">
              <FormattedMessage id={translationKeys.finished_booking_login_button} />
            </Button>
          </Link>
          <span className={styles.loginButtonSubtitle}>
            <FormattedMessage id={translationKeys.finished_booking_login_button_subtitle} />
          </span>
        </>
      ) : (
        <Formik
          initialValues={{
            caseNumber: '',
            description: '',
            otherSubject: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
            <Form className={styles.form} onSubmit={handleSubmit}>
              <div>
                <h3 className={styles.heading} data-testid="form-title">
                  <FormattedMessage id={translationKeys.finished_booking_form_title} />
                </h3>
                <span className={styles.description} data-testid="form-subtitle">
                  <FormattedMessage id={translationKeys.finished_booking_form_subtitle} />
                </span>
              </div>
              <SearchDropdown
                options={subjectsList}
                placeholder={intl.formatMessage({
                  id: translationKeys.finished_booking_subject_placeholder,
                })}
                label={intl.formatMessage({
                  id: translationKeys.finished_booking_subject_label,
                })}
                size="large"
                onChange={(subjects) => {
                  if (subjects && subjects.length > 0) {
                    setSelectedSubject(subjects[0]);
                  } else {
                    setSelectedSubject(undefined);
                  }
                }}
              />

              {selectedSubject && selectedSubject.key === 'other' && (
                <TextField
                  data-testid="other-subject-input"
                  placeholder={intl.formatMessage({
                    id: translationKeys.finished_booking_other_subject_placeholder,
                  })}
                  label={intl.formatMessage({
                    id: translationKeys.finished_booking_other_subject_placeholder,
                  })}
                  size="large"
                  name="otherSubject"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.otherSubject}
                  errorText={touched.otherSubject ? errors.otherSubject : undefined}
                />
              )}

              {order?.logoutCustomerType != LoggedOutCustomerType.New && (
                <TextField
                  data-testid="case-number-input"
                  placeholder={intl.formatMessage({
                    id: translationKeys.finished_booking_case_number_placeholder,
                  })}
                  label={intl.formatMessage({
                    id: translationKeys.finished_booking_case_number_label,
                  })}
                  size="large"
                  maxLength={32}
                  name="caseNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.caseNumber}
                  errorText={touched.caseNumber ? errors.caseNumber : undefined}
                />
              )}

              <div className={styles.descriptionContainer}>
                <TextField
                  data-testid="description-input"
                  placeholder={intl.formatMessage({
                    id: translationKeys.finished_booking_description_placeholder,
                  })}
                  type="text"
                  label={intl.formatMessage({
                    id: translationKeys.finished_booking_description_label,
                  })}
                  size="large"
                  textArea
                  maxLength={100}
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  errorText={touched.description ? errors.description : undefined}
                />
                <span className={styles.maxCharacter}>
                  <FormattedMessage
                    id={translationKeys.finished_booking_description_max_characters}
                    values={{
                      remainingCharacter: 100 - values.description.length,
                    }}
                  />
                </span>
              </div>

              <Button
                data-testid="submit-button"
                type="submit"
                className={styles.saveButton}
                size="large"
                variant="primary"
                fullWidth={true}
                disabled={isAdditionalInfoSubmitted}
                isLoading={updateAdditionalInfoLoading}
              >
                <FormattedMessage id={translationKeys.finished_booking_save_button} />
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default FinishedBooking;
