import { BookingReferenceResponse } from '../../types/BookingReference';
import Language from '../../types/Language';
import fetchApi, { UrlParams } from '../fetchApi';

export default {
  getLanguages: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<Language[]>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/languages`,
      loadingCallback,
    });
  },
  getAddressList: (urlParams: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<any[]>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/geocodes`,
      urlParams,
      loadingCallback,
    });
  },
  getOrgReferencesRegex: (orgNumber: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<BookingReferenceResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/references_regular_expressions/${orgNumber}`,
      loadingCallback,
    });
  },
};
