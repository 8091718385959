import { useCallback, useRef } from 'react';

function useDebounce(delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutDelay = process.env.NODE_ENV === 'test' ? 0 : delay;

  const debouncedCallback = useCallback(
    (callback: () => void) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(callback, timeoutDelay);
    },
    [timeoutDelay],
  );

  return debouncedCallback;
}

export default useDebounce;
