import React, { createContext, useContext } from 'react';
import { ApiDefinition } from '../Api';

interface Props {
  value: ApiDefinition;
  children: JSX.Element | JSX.Element[];
}

export const ApiContext = createContext<ApiDefinition | undefined>(undefined);

const ApiProvider: React.FC<Props> = ({ value, children }) => {
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const context = useContext(ApiContext);

  if (context === undefined) {
    throw new Error('useApi must be used within a ApiContext.Provider');
  }

  return context;
};

export default ApiProvider;
