import React, { useCallback, useState } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import BookingEstimate from './components/BookingEstimate/BookingEstimate';
import ContactInformation from './components/ContactInformation/ContactInformation';
import FinishedBooking from './components/FinishedBooking/FinishedBooking';
import OrderInformation from './components/OrderInformation/OrderInformation';
import { initializeGA } from './helpers/GoogleAnalytics';
import useHotjar from './hooks/useHotjar';
import LanguagesProvider from './providers/LanguagesProvider';
import { useOrderBooking } from './providers/OrderBookingProvider';
import { useOrderInfo } from './providers/OrderInfoProvider';
import styles from './App.module.scss';

function App() {
  const { isOrderCreated } = useOrderBooking();
  const { sourceLanguage, targetLanguages } = useOrderInfo();
  useHotjar();
  initializeGA();

  const [isEstimateVisible, setIsEstimateVisible] = useState(false);

  const OrderCreatedRoutes = useCallback(() => {
    if (!isOrderCreated) {
      return <Navigate to="/" replace />;
    }

    return <Outlet />;
  }, [isOrderCreated]);

  const OrderInfoFilled = () => {
    if (!sourceLanguage && targetLanguages && !targetLanguages.length) {
      return <Navigate to="/" replace />;
    }

    return <Outlet />;
  };

  const updateIsEstimateVisible = (isVisible: boolean) => {
    setIsEstimateVisible(isVisible);
  };

  return (
    <div className={styles.app}>
      <div className={styles.content}>
        <LanguagesProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<OrderInformation />} />
              <Route element={<OrderInfoFilled />}>
                <Route path="contact" element={<ContactInformation />} />
              </Route>
              <Route element={<OrderCreatedRoutes />}>
                <Route
                  path="estimate"
                  element={<BookingEstimate updateIsEstimateVisible={updateIsEstimateVisible} />}
                />
                <Route
                  path="order-finished"
                  element={<FinishedBooking isEstimateVisible={isEstimateVisible} />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </LanguagesProvider>
      </div>
    </div>
  );
}

export default App;
