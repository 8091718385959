import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { CalendarContainer, CalendarContainerProps } from 'react-datepicker';
import { createIntl } from 'react-intl';
import { getLanguage } from '@skiwo/utils';
import Button from '../../Button/Button';
import languages from '../../translations/languages';
import translationKeys from '../../translations/translationKeys';
import DatePickerPreset from '../DatePickerPreset';
import styles from './DatePickerContainer.module.scss';

interface Props {
  selectedPreset?: DatePickerPreset;
  setSelectedPreset?: (preset: DatePickerPreset) => void;
  onClose?: () => void;
  onChange?: () => void;
  singleDate?: boolean;
}

const DatePickerContainer = (props: Props & CalendarContainerProps) => {
  const { onClose, onChange, selectedPreset, singleDate, setSelectedPreset, ...calendarProps } =
    props;
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const presets = [
    {
      name: intl.formatMessage({ id: translationKeys.date_picker_today_label }),
      id: DatePickerPreset.Today,
    },
    {
      name: intl.formatMessage({ id: translationKeys.date_picker_yesterday_label }),
      id: DatePickerPreset.Yesterday,
    },
    {
      name: intl.formatMessage({ id: translationKeys.date_picker_this_month_label }),
      id: DatePickerPreset.ThisMonth,
    },
    {
      name: intl.formatMessage({ id: translationKeys.date_picker_previous_month_label }),
      id: DatePickerPreset.PreviousMonth,
    },
    {
      name: intl.formatMessage({ id: translationKeys.date_picker_previous_year_label }),
      id: DatePickerPreset.PreviousYear,
    },
  ];

  const handleChangePreset = (preset: DatePickerPreset) => {
    if (setSelectedPreset) {
      setSelectedPreset(preset);
    }
  };

  const handleOnSubmit = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className={styles.calendarContainer}>
      {!singleDate && (
        <ListGroup
          className={styles.presets}
          activeKey={selectedPreset}
          data-testid="calendar-presets"
        >
          {presets.map((preset) => (
            <ListGroup.Item
              action
              data-testid="calendar-preset-item"
              className={styles.presetItem}
              eventKey={preset.id}
              key={preset.id}
              onClick={() => handleChangePreset(preset.id)}
            >
              {preset.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      <div>
        <CalendarContainer {...calendarProps} />
        {!singleDate && (
          <div className={styles.actionButtons} data-testid="calendar-action-buttons">
            <Button variant="gray" onClick={onClose} data-testid="cancel-button">
              {intl.formatMessage({ id: translationKeys.date_picker_cancel_button })}
            </Button>
            <Button onClick={handleOnSubmit} data-testid="submit-button">
              {intl.formatMessage({ id: translationKeys.date_picker_submit_button })}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePickerContainer;
