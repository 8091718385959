import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { getLanguage } from '@skiwo/utils';
import ApiProvider from './providers/ApiProvider';
import OrderBookingProvider from './providers/OrderBookingProvider';
import OrderInfoProvider from './providers/OrderInfoProvider';
import languages from './translations/languages';
import Api from './Api';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@skiwo/styles/global/base.scss';

const urlParams = new URLSearchParams(window.location.search);
const localeParam = urlParams.get('locale');
const supportedLocales = ['nb-NO', 'en-GB', 'uk-UA'];
let language = '';
const supportedLanguages = Object.keys(languages);
if (localeParam && supportedLocales.includes(localeParam)) {
  localStorage.setItem('locale', localeParam);
  language = localeParam.split('-')[0];
} else {
  language = getLanguage();
}
let translations = languages[supportedLanguages[0]];
if (supportedLanguages.includes(language)) {
  translations = languages[language];
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={translations}>
      <ApiProvider value={Api}>
        <OrderBookingProvider>
          <OrderInfoProvider>
            <App />
          </OrderInfoProvider>
        </OrderBookingProvider>
      </ApiProvider>
    </IntlProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
