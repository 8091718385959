import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { LoggedOutCustomerType } from '../types/LogoutCustomer';
import { IOrderBookingContext, Order } from '../types/Order';
import { useApi } from './ApiProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const initialState: IOrderBookingContext = {
  setLogoutOrderToken: () => null,
  handleSetOrder: () => null,
  handleSetIsOrderCreated: () => null,
  handleSetCustomerType: () => null,
  getLogoutOrderToken: async () => null,
  isOrderCreated: false,
  order: undefined,
  customerType: LoggedOutCustomerType.New,
};

export const OrderBookingContext = createContext(initialState);

const COOKIE_LOGOUT_ORDER_TOKEN = 'token';

const OrderBookingProvider: React.FC<Props> = ({ children }) => {
  const api = useApi();
  const [order, setOrder] = useState<Order>();
  const [isOrderCreated, setIsOrderCreated] = useState<boolean>(false);
  const [customerType, setCustomerType] = useState<LoggedOutCustomerType>(
    LoggedOutCustomerType.New,
  );

  const setLogoutOrderToken = (newToken: string, expiresAt: number) => {
    Cookies.set(COOKIE_LOGOUT_ORDER_TOKEN, newToken, { expires: expiresAt });
  };

  const handleSetOrder = (order: Order) => {
    setOrder(order);
  };

  const handleSetIsOrderCreated = (isOrderCreated: boolean) => {
    setIsOrderCreated(isOrderCreated);
  };

  const handleSetCustomerType = (customerType: LoggedOutCustomerType) => {
    setCustomerType(customerType);
  };

  const getLogoutOrderToken = async (forceCreate?: boolean) => {
    const token = Cookies.get('token') || null;

    if (!token || forceCreate) {
      const { data } = await api.createLogoutTokens('translation');

      if (data) {
        setLogoutOrderToken(data.token, data.expires_at);
        return data.token;
      }
    }

    return token;
  };

  useEffect(() => {
    getLogoutOrderToken();
  }, []);

  const OrderBookingContextValue = useMemo(
    () => ({
      setLogoutOrderToken,
      handleSetOrder,
      handleSetIsOrderCreated,
      handleSetCustomerType,
      getLogoutOrderToken,
      isOrderCreated,
      order,
      customerType,
    }),
    [
      setLogoutOrderToken,
      handleSetOrder,
      handleSetIsOrderCreated,
      handleSetCustomerType,
      getLogoutOrderToken,
      isOrderCreated,
      order,
      customerType,
    ],
  );

  return (
    <OrderBookingContext.Provider value={OrderBookingContextValue}>
      {children}
    </OrderBookingContext.Provider>
  );
};

export const useOrderBooking = () => useContext(OrderBookingContext);

export default OrderBookingProvider;
