import React from 'react';
import Alert from 'react-bootstrap/Alert';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './Banner.module.scss';

interface Props {
  text: React.ReactNode;
  variant?: 'information' | 'success' | 'warning' | 'error';
  title?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  'data-testid'?: string;
}

function Banner(props: Props) {
  const { children, text, title, variant = 'information', onClose } = props;

  const getVariant = () => {
    if (variant == 'success') {
      return 'success';
    }
    if (variant == 'error') {
      return 'danger';
    }

    if (variant === 'warning') {
      return 'warning';
    }

    return 'info';
  };

  const getIconForVariant = () => {
    if (variant == 'success') {
      return (
        <FontAwesomeIcon
          icon={faCircleCheck}
          data-testid="banner-icon-success"
          className={classnames(styles.icon, styles.success)}
        />
      );
    }
    if (variant == 'error') {
      return (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          data-testid="banner-icon-error"
          className={classnames(styles.icon, styles.error)}
        />
      );
    }

    if (variant === 'warning') {
      return (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          data-testid="banner-icon-warning"
          className={classnames(styles.icon, styles.warning)}
        />
      );
    }

    return (
      <FontAwesomeIcon
        icon={faCircleInfo}
        data-testid="banner-icon-information"
        className={classnames(styles.icon, styles.information)}
      />
    );
  };

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Alert
      data-testid={props['data-testid'] || 'banner'}
      className={styles.banner}
      variant={getVariant()}
      onClose={handleOnClose}
      dismissible={!!onClose}
    >
      {title && (
        <Alert.Heading className={styles.title}>
          {!onClose && getIconForVariant()}
          <span data-testid="banner-title">{title}</span>
        </Alert.Heading>
      )}

      <div className={classnames(styles.body, { [styles.leftPad]: !onClose && title })}>
        {!onClose && !title && getIconForVariant()}

        <span data-testid="banner-text">{text}</span>
        {children && <div className={styles.content}>{children}</div>}
      </div>
    </Alert>
  );
}

export default Banner;
