import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ApiError } from '../Api';
import Language from '../types/Language';
import { useApi } from './ApiProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface LanguagesContext {
  languages: Language[];
  isLoading: boolean;
  error: ApiError | null;
  getLanguageById: (languageId: number) => string | undefined;
}

export const initialState: LanguagesContext = {
  languages: [],
  isLoading: false,
  error: null,
  getLanguageById: () => undefined,
};

export const LanguagesContext = createContext(initialState);

const LanguagesProvider: React.FC<Props> = ({ children }) => {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [languages, setLanguages] = useState<Language[]>([]);

  const getLanguages = async () => {
    const { data, error } = await api.getLanguages(setIsLoading);

    if (data) {
      setLanguages(data);
    }

    setError(error);
  };

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguageById = (languageId: number) => {
    if (languages) {
      const languageObject = languages.find((language: any) => language.id === languageId);

      if (languageObject) {
        return languageObject.name;
      }
    }

    return '-';
  };

  const LanguagesContextValue = useMemo(
    () => ({
      languages,
      isLoading,
      error,
      getLanguageById,
    }),
    [languages, isLoading, error],
  );

  return (
    <LanguagesContext.Provider value={LanguagesContextValue}>{children}</LanguagesContext.Provider>
  );
};

export const useLanguages = () => useContext(LanguagesContext);

export default LanguagesProvider;
