import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { getLanguage } from '@skiwo/utils';
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import en from 'date-fns/locale/en-GB';
import nb from 'date-fns/locale/nb';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
import DatePickerContainer from '../DatePickerContainer/DatePickerContainer';
import DatePickerPreset from '../DatePickerPreset';
import './DatePickerCalendar.scss';

interface Props {
  onClose?: () => void;
  onChange?: (start: Date, end?: Date) => void;
  monthCount?: number;
  className?: any;
  singleDate?: boolean;
  minDate?: Date;
  selected?: Date;
}

const DatePickerCalendar = (props: Props) => {
  const { onClose, onChange, monthCount, className, singleDate = false, minDate, selected } = props;
  const [startDate, setStartDate] = useState<Date | null>(selected || null);
  const [endDate, setEndDate] = useState<Date | null>();
  const [selectedPreset, setSelectedPreset] = useState<DatePickerPreset>();
  const userLanguage = getLanguage();

  registerLocale('en', en);
  registerLocale('nb', nb);
  registerLocale('uk', uk);

  const handleSelectionChange = (dates: (Date | null) | [Date | null, Date | null]) => {
    if (!singleDate && Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start ? startOfDay(start) : null);
      setEndDate(end ? endOfDay(end) : null);
    } else if (dates instanceof Date) {
      setStartDate(dates ? startOfDay(dates) : null);
    }
  };

  const handleStartDateChange = (start: Date) => {
    setStartDate(start);
  };

  const handleEndDateChange = (end: Date) => {
    setEndDate(end);
  };

  const handleDateChange = () => {
    if (onChange && startDate) {
      onChange(startDate, endDate || undefined);
    }
  };

  const handleSelect = (date: Date) => {
    if (onChange && date) {
      onChange(date, undefined);
    }
  };

  const handleChangePreset = (preset: DatePickerPreset) => {
    if (setSelectedPreset) {
      setSelectedPreset(preset);
    }

    const currentDate = new Date();
    const yesterday = subDays(currentDate, 1);
    const lastMonth = subMonths(currentDate, 1);
    const lastYear = subYears(currentDate, 1);

    switch (preset) {
      case DatePickerPreset.Today:
        handleStartDateChange(startOfDay(currentDate));
        handleEndDateChange(endOfDay(currentDate));
        break;
      case DatePickerPreset.Yesterday:
        handleStartDateChange(startOfDay(yesterday));
        handleEndDateChange(endOfDay(yesterday));
        break;
      case DatePickerPreset.ThisMonth:
        handleStartDateChange(startOfMonth(currentDate));
        handleEndDateChange(endOfMonth(currentDate));
        break;
      case DatePickerPreset.PreviousMonth:
        handleStartDateChange(startOfMonth(lastMonth));
        handleEndDateChange(endOfMonth(lastMonth));
        break;
      case DatePickerPreset.PreviousYear:
        handleStartDateChange(startOfYear(lastYear));
        handleEndDateChange(endOfYear(lastYear));
        break;
    }
  };

  return (
    <div data-testid="date-picker-calendar" className={className}>
      <DatePicker
        selectsRange={!singleDate}
        selectsStart
        inline
        calendarClassName="calendar"
        calendarStartDay={1}
        shouldCloseOnSelect={false}
        locale={userLanguage}
        monthsShown={window.innerWidth < 780 ? 1 : monthCount}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onClickOutside={onClose}
        onChange={handleSelectionChange}
        onSelect={(date) => (singleDate ? handleSelect(date) : null)}
        minDate={minDate}
        calendarContainer={(props) => (
          <DatePickerContainer
            {...props}
            onClose={onClose}
            onChange={handleDateChange}
            singleDate={singleDate}
            selectedPreset={selectedPreset}
            setSelectedPreset={handleChangePreset}
          />
        )}
      />
    </div>
  );
};

export default DatePickerCalendar;
