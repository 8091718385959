// This file is automatically generated; Do not edit.
const translationKeys = {
  date_picker_cancel_button: 'date_picker_cancel_button',
  date_picker_previous_month_label: 'date_picker_previous_month_label',
  date_picker_previous_year_label: 'date_picker_previous_year_label',
  date_picker_submit_button: 'date_picker_submit_button',
  date_picker_this_month_label: 'date_picker_this_month_label',
  date_picker_today_label: 'date_picker_today_label',
  date_picker_yesterday_label: 'date_picker_yesterday_label',
  dropdown_menu_clear_selected: 'dropdown_menu_clear_selected',
  empty_state_refresh_button: 'empty_state_refresh_button',
  enterprise_card_departments_label: 'enterprise_card_departments_label',
  enterprise_card_members_label: 'enterprise_card_members_label',
  enterprise_card_org_number_label: 'enterprise_card_org_number_label',
  enterprise_card_owner_label: 'enterprise_card_owner_label',
  file_uploader_actions_delete: 'file_uploader_actions_delete',
  file_uploader_actions_download: 'file_uploader_actions_download',
  file_uploader_actions_rename: 'file_uploader_actions_rename',
  file_uploader_calculating_ocr_status_text:
    'file_uploader_calculating_ocr_status_text',
  file_uploader_error_empty_word_count: 'file_uploader_error_empty_word_count',
  file_uploader_error_estimating_word_count:
    'file_uploader_error_estimating_word_count',
  file_uploader_error_estimating_word_count_taking_time:
    'file_uploader_error_estimating_word_count_taking_time',
  file_uploader_error_file_exists: 'file_uploader_error_file_exists',
  file_uploader_error_files_limit: 'file_uploader_error_files_limit',
  file_uploader_error_invalid_file_type:
    'file_uploader_error_invalid_file_type',
  file_uploader_error_max_file_size: 'file_uploader_error_max_file_size',
  file_uploader_error_number_of_files: 'file_uploader_error_number_of_files',
  file_uploader_error_upload_failed: 'file_uploader_error_upload_failed',
  file_uploader_hint: 'file_uploader_hint',
  file_uploader_main_description: 'file_uploader_main_description',
  file_uploader_main_description_action_text:
    'file_uploader_main_description_action_text',
  file_uploader_rename_modal_file_name_label:
    'file_uploader_rename_modal_file_name_label',
  file_uploader_rename_modal_save_button:
    'file_uploader_rename_modal_save_button',
  file_uploader_rename_modal_subtitle_text:
    'file_uploader_rename_modal_subtitle_text',
  file_uploader_rename_modal_title: 'file_uploader_rename_modal_title',
  file_uploader_retry_button: 'file_uploader_retry_button',
  file_uploader_retry_upload_button: 'file_uploader_retry_upload_button',
  file_uploader_uploading_status_text: 'file_uploader_uploading_status_text',
  file_uploader_word_count_label: 'file_uploader_word_count_label',
  file_uploader_words_text: 'file_uploader_words_text',
  modal_cancel_button: 'modal_cancel_button',
  search_dropdown_empty_items_state: 'search_dropdown_empty_items_state',
  search_dropdown_items_selected: 'search_dropdown_items_selected',
  search_dropdown_loading_state: 'search_dropdown_loading_state',
  tooltip_click_to_copy_text: 'tooltip_click_to_copy_text',
};

export default translationKeys;
